import React from "react"

const IconSpotify = ({ className }) => (
  <svg
    className="{{ class }}"
    viewBox="0 0 713 713"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="2"
  >
    <path
      d="M560.675 318.634C448.2 251.838 262.671 245.696 155.3 278.288c-17.242 5.229-35.475-4.504-40.7-21.746-5.225-17.25 4.5-35.471 21.754-40.713 123.255-37.416 328.15-30.187 457.634 46.675 15.508 9.205 20.596 29.234 11.404 44.717-9.2 15.508-29.246 20.62-44.717 11.413zm-3.683 98.933c-7.892 12.804-24.633 16.82-37.42 8.958-93.772-57.637-236.76-74.333-347.697-40.662-14.387 4.35-29.583-3.763-33.95-18.121-4.337-14.388 3.78-29.554 18.138-33.93 126.733-38.453 284.279-19.828 391.98 46.355 12.787 7.875 16.812 24.63 8.95 37.4zm-42.696 95.013c-6.27 10.283-19.658 13.504-29.904 7.237-81.942-50.08-185.075-61.387-306.534-33.646-11.704 2.684-23.366-4.65-26.037-16.354-2.68-11.7 4.625-23.367 16.358-26.037 132.913-30.384 246.93-17.309 338.9 38.891 10.255 6.263 13.484 19.659 7.217 29.909zM354.271 9.3C161.563 9.3 5.338 165.521 5.338 358.225c0 192.725 156.225 348.934 348.933 348.934 192.713 0 348.93-156.209 348.93-348.934C703.2 165.521 546.984 9.3 354.27 9.3z"
      fill="#fff"
      fillRule="nonzero"
    />
  </svg>
)

export default IconSpotify
