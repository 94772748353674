import React from "react"
import styled from "styled-components"
import { Box } from "grommet"
import FluidImage from "./FluidImage"
import Logo from "./Logo"

const StyledLogo = styled(Logo)`
  svg {
    width: 200px;
  }
`

const LogoContainer = styled(Box)`
  width: 100px;
  position: absolute;
  right: 1em;
  top: 1em;
  z-index: 10;
  @media (max-width: 414px) {
    width: 61px;
  }
  @media (max-width: 320px) {
    width: 51px;
  }
`

const Header = ({ headerImage, logoColor }) => (
  <header>
    <LogoContainer>
      <a href="https://www.backdrophome.com">
        <StyledLogo fill={logoColor} />
      </a>
    </LogoContainer>
    {headerImage && (
      <Box>
        <a href="https://backdrophome.com">
          <FluidImage
            objectFit="contain"
            objectPosition="center center"
            fluid={headerImage}
          />
        </a>
      </Box>
    )}
  </header>
)

export default Header
