import { css } from "styled-components"

export const normalizeColor = (color, theme, required) => {
  const colorSpec = theme.global.colors[color] || color
  // If the color has a light or dark object, use that
  let result = colorSpec
  if (colorSpec) {
    if (theme.dark && colorSpec.dark) {
      result = colorSpec.dark
    } else if (!theme.dark && colorSpec.light) {
      result = colorSpec.light
    }
  }
  // allow one level of indirection in color names
  if (result && theme.global.colors[result]) {
    result = normalizeColor(result, theme)
  }
  return required && result === color ? "inherit" : result
}

export const focusStyle = css`
  > circle,
  > ellipse,
  > line,
  > path,
  > polygon,
  > polyline,
  > rect {
    outline: ${props =>
        normalizeColor(props.theme.global.focus.border.color, props.theme)}
      solid 2px;
  }
  border-color: ${props =>
    normalizeColor(props.theme.global.focus.border.color, props.theme)};
  box-shadow: 0 0 2px 2px
    ${props =>
      normalizeColor(props.theme.global.focus.border.color, props.theme)};
  ::-moz-focus-inner {
    border: 0;
  }
`
