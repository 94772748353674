const baseSpacing = 34
const scale = 7.5
const baseFontSize = 18
const fontScale = baseSpacing / scale // 4

const fontSizing = (factor, lineHeight = 0.75) => ({
  size: `${baseFontSize + factor * fontScale}px`,
  height: `${baseSpacing + (factor + lineHeight) * fontScale}px`,
  // maxWidth chosen to be ~50 characters wide
  // see: https://ux.stackexchange.com/a/34125
  maxWidth: `${baseSpacing * (baseFontSize + (factor + 1) * fontScale)}px`,
})

export default {
  global: {
    baseSpacing,
    colors: {
      focus: "rgba(0,0,0,0.15)",
      "dark-1": "#333333",
      brand: "#000",
      "status-critical": "#F45F5F",
      text: {
        light: "#000",
        dark: "#fff",
      },
      control: {
        dark: "white",
        light: "black",
      },
    },
    hover: {
      background: "black",
      color: "white",
    },
    font: {
      family: "akzidenz-grotesk,sans-serif",
      ...fontSizing(0),
    },
    focus: {
      border: {
        color: "focus",
        weight: 0,
      },
    },
    elevation: {
      light: {
        none: "none",
        xsmall: "0px 1px 2px rgba(0, 0, 0, 0.20)",
        small: "0px 2px 4px rgba(0, 0, 0, 0.20)",
        medium: "0px 4px 8px rgba(0, 0, 0, 0.20)",
        large: "0px 8px 16px rgba(0, 0, 0, 0.20)",
        xlarge: "0px 12px 24px rgba(0, 0, 0, 0.20)",
      },
      dark: {
        none: "none",
        xsmall: "0px 2px 2px rgba(0, 0, 0, 0.40)",
        small: "0px 4px 4px rgba(0, 0, 0, 0.40)",
        medium: "0px 6px 8px rgba(0, 0, 0, 0.40)",
        large: "0px 8px 16px rgba(0, 0, 0, 0.40)",
        xlarge: "0px 12px 24px rgba(0, 0, 0, 0.40)",
      },
    },
    borderSize: {
      xsmall: "1px",
      small: "2px",
      medium: `${baseSpacing / 6}px`, // 4
      large: `${baseSpacing / 2}px`, // 12
      xlarge: `${baseSpacing}px`, // 24
    },
    breakpoints: {
      small: {
        value: baseSpacing * 32, // 768
        borderSize: {
          xsmall: "1px",
          small: "2px",
          medium: `${baseSpacing / 6}px`, // 4
          large: `${baseSpacing / 4}px`, // 6
          xlarge: `${baseSpacing / 2}px`, // 12
        },
        edgeSize: {
          none: "0px",
          hair: "1px", // for Chart
          xxsmall: "2px",
          xsmall: `${baseSpacing / 8}px`, // 3
          small: `${baseSpacing / 8}px`, // 6
          medium: `${baseSpacing / 2}px`, // 12
          large: `${baseSpacing}px`, // 24
          xlarge: `${baseSpacing * 2}px`, // 48
        },
        size: {
          xxsmall: `${baseSpacing}px`, // 24
          xsmall: `${baseSpacing * 2}px`, // 48
          small: `${baseSpacing * 4}px`, // 96
          medium: `${baseSpacing * 8}px`, // 192
          large: `${baseSpacing * 16}px`, // 384
          xlarge: `${baseSpacing * 32}px`, // 768
          full: "100%",
        },
      },
      medium: {
        value: baseSpacing * 64, // 1536
      },
      large: {}, // anything above 'medium'
    },
    edgeSize: {
      none: "0px",
      hair: "1px", // for Chart
      xxsmall: `${baseSpacing / 8}px`, // 3
      xsmall: `${baseSpacing / 4}px`, // 6
      small: `${baseSpacing / 3}px`, // 12
      medium: `${baseSpacing}px`, // 24
      large: `${baseSpacing}px`, // 48
      xlarge: `${baseSpacing * 2}px`, // 96
      responsiveBreakpoint: "small",
    },
    input: {
      padding: `${baseSpacing / 2}px`,
      weight: "normal",
    },
    control: {
      border: {
        radius: 0,
        width: "0",
      },
    },
  },
  text: {
    xsmall: { ...fontSizing(-1.5) },
    small: { ...fontSizing(-1) },
    medium: { ...fontSizing(0) },
    large: { ...fontSizing(0) },
    xlarge: { ...fontSizing(1) },
    xxlarge: { ...fontSizing(1) },
  },
  paragraph: {
    xsmall: { ...fontSizing(-1.5) },
    small: { ...fontSizing(-1) },
    medium: { ...fontSizing(0, -0.75) },
    large: { ...fontSizing(0, 20) },
    xlarge: { ...fontSizing(1) },
    xxlarge: { ...fontSizing(1) },
  },
  maskedInput: {
    extend: `
    background: white;
    color: black;
    font-weight: 500;
    ::placeholder {
      font-weight: normal;
    }
    `,
  },
  textInput: {
    extend: `
      background: white;
      color: black;
      font-weight: 500;
      ::placeholder {
        font-weight: normal;
      }
    `,
    container: {
      extend: {},
    },
    suggestions: {
      extend: `
        li button {
          padding: 0 1em
        }
      `,
    },
  },
  textArea: {
    extend: `
      background: white;
      color: black;
      font-weight: 500;
      font-size: 18px;
      ::placeholder {
        font-weight: normal;
      }
      line-height: 1.53333;
      @media (min-width: 768px) {
        line-height: 1.58824;
      }
    `,
  },
  button: {
    extend: {
      textTransform: "uppercase",
      fontWeight: "500",
      textAlign: "center",
    },
    color: {
      dark: "white",
      light: "black",
    },
    border: {
      radius: 0,
      color: "black",
      width: "2px",
    },
    padding: {
      vertical: `${baseSpacing / 2.5}px`,
      horizontal: `${baseSpacing}px`,
    },
  },
  anchor: {
    fontWeight: "100",
    textDecoration: "underline",
    color: {
      dark: "#fff",
      light: "brand",
    },
  },
  heading: {
    font: {
      family: "akzidenz-grotesk,sans-serif",
      transform: "uppercase",
    },
    level: {
      1: {
        font: {
          weight: 500,
        },
        small: { ...fontSizing(1.75) },
        medium: { ...fontSizing(4.25, -0.75) },
        large: { ...fontSizing(8.25, -0.75) },
        xlarge: { ...fontSizing(8.25) },
      },
      2: {
        font: {
          weight: 500,
        },
      },
      3: {
        font: {
          weight: 500,
        },
      },
      4: {
        medium: {
          maxWidth: "none",
        },
      },
    },
  },
}
