import React from "react"
import { Link as GatsbyLink } from "gatsby"
import { Anchor } from "grommet"
import { normalizeColor, focusStyle } from "../lib/styleUtils"
import styled, { css } from "styled-components"

const disabledStyle = `
  opacity: 0.3;
  cursor: default;
  text-decoration: none;
`

const sizeStyle = props => {
  if (props.size) {
    const size = props.size || "medium"
    const data = props.theme.text[size]
    return css`
      font-size: ${data.size};
      line-height: ${data.height};
    `
  }
  return css`
    font-size: inherit;
    line-height: inherit;
  `
}

const StyledLink = styled(GatsbyLink)`
  box-sizing: border-box;
  ${props => sizeStyle(props)}
  color: ${props =>
    normalizeColor(props.colorProp || props.theme.anchor.color, props.theme)};
  ${props =>
    props.theme.anchor.fontWeight &&
    `font-weight: ${props.theme.anchor.fontWeight};`}
  text-decoration: ${props =>
    props.hasIcon ? "none" : props.theme.anchor.textDecoration};
  cursor: pointer;
  outline: none;

  ${props =>
    !props.disabled &&
    props.theme.anchor.hover &&
    css`
    &:hover {
      ${props.theme.anchor.hover.textDecoration &&
        `text-decoration: ${props.theme.anchor.hover.textDecoration};`}
      ${props.theme.anchor.hover.fontWeight &&
        `font-weight: ${props.theme.anchor.hover.fontWeight};`}
      ${props.theme.anchor.hover.extend}
    }
  `}
  ${props =>
    props.hasIcon &&
    !props.hasLabel &&
    `
    padding: ${props.theme.global.edgeSize.small};
  `}
  ${props => props.disabled && disabledStyle}
  ${props => props.focus && focusStyle}
  ${props => props.theme.anchor.extend}
`

// Since DOM elements <a> cannot receive activeClassName,
// destructure the prop here and pass it only to GatsbyLink
const SmartLink = ({ children, to, activeClassName, target, ...other }) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = target !== "_blank" && /^\/(?!\/)/.test(to)

  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <StyledLink to={to} activeClassName={activeClassName} {...other}>
        {children}
      </StyledLink>
    )
  }
  return (
    <Anchor target={target} href={to} {...other}>
      {children}
    </Anchor>
  )
}
export default SmartLink
