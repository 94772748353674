import React, { useContext } from "react"
import styled from "styled-components"
import { Box, Text, Heading, ResponsiveContext } from "grommet"
import IconInstagram from "./icons/IconInstagram"
import IconSpotify from "./icons/IconSpotify"
import IconPinterest from "./icons/IconPinterest"
import IconFacebook from "./icons/IconFacebook"
import IconTwitter from "./icons/IconTwitter"

import SmartLink from "./SmartLink"

const NAVIGATION = [
  {
    title: "Shop",
    id: "shop",
    menus: [
      [
        {
          title: "Interior Paint",
          id: "interior-paint",
          links: [
            {
              text: "Standard",
              url: "https://www.backdrophome.com/collections/interior-standard",
            },
            {
              text: "Semi-Gloss",
              url:
                "https://www.backdrophome.com/collections/interior-semi-gloss",
            },
            {
              text: "Primer",
              url: "https://www.backdrophome.com/products/primer",
            },
          ],
        },
        {
          title: "Supplies",
          id: "supplies",
          links: [
            {
              text: "Painting Supplies",
              url: "https://www.backdrophome.com/collections/supplies",
            },
            {
              text: "The Essentials Kit",
              url: "https://www.backdrophome.com/products/essentials-kit",
            },
            {
              text: "Painter's Shirt",
              url: "https://www.backdrophome.com/products/paintershirt",
            },
          ],
        },
      ],
      [
        {
          title: "Exterior Paint",
          id: "exterior-paint",
          links: [
            {
              text: "Standard",
              url: "https://www.backdrophome.com/collections/exterior-standard",
            },
            {
              text: "Semi-Gloss",
              url:
                "https://www.backdrophome.com/collections/exterior-semi-gloss",
            },
            {
              text: "Primer",
              url: "https://www.backdrophome.com/products/exterior-primer",
            },
          ],
        },
        {
          title: "Services",
          id: "services",
          links: [
            {
              text: "Painting Services",
              url: "https://www.backdrophome.com/pages/painting-services",
            },
            {
              text: "Color Consultation",
              url: "https://www.backdrophome.com/pages/color-consultation",
            },
          ],
        },
      ],
    ],
  },
  {
    collapse: true,
    id: "discover-learn",
    categories: [
      {
        title: "Discover",
        id: "discover",
        menus: [
          [
            {
              title: "Information",
              id: "information",
              links: [
                {
                  text: "Paint Calculator",
                  url: "https://www.backdrophome.com/pages/calculator",
                },
                {
                  text: "FAQ",
                  url: "https://www.backdrophome.com/pages/faq",
                },
                {
                  text: "How To's",
                  url: "https://www.backdrophome.com/pages/the-manual",
                },
                {
                  text: "Trade Program",
                  url: "https://www.backdrophome.com/pages/industry",
                },
                {
                  text: "Reviews",
                  url: "https://www.backdrophome.com/pages/customer-reviews",
                },
                {
                  text: "The Gallery",
                  url: "https://www.backdrophome.com/pages/gallery",
                },
              ],
            },
          ],
        ],
      },
      {
        title: "Learn More",
        id: "learn",
        menus: [
          [
            {
              title: "Company",
              id: "company",
              links: [
                {
                  text: "About Us",
                  url: "https://www.backdrophome.com/pages/about",
                },
                {
                  text: "Jobs",
                  url: "https://www.backdrophome.com/pages/jobs",
                },
                {
                  text: "Press",
                  url: "https://www.backdrophome.com/pages/press",
                },
                {
                  text: "Contact",
                  url: "mailto:hello@backdrophome.com",
                },
              ],
            },
          ],
        ],
      },
    ],
  },
]

const SOCIAL_LINKS = [
  { service: "instagram", url: "https://www.instagram.com/backdrop/" },
  { service: "pinterest", url: "https://www.pinterest.com/backdrop/" },
  {
    service: "spotify",
    url: "https://open.spotify.com/user/30omy87tmx717vpqdlej1ko2i",
  },
  {
    service: "facebook",
    url: "https://www.facebook.com/BackdropBackdropBackdrop/",
  },
  { service: "twitter", url: "https://twitter.com/Backdrop_Home" },
]

const FooterContainer = styled(Box)`
  width: 100%;
  font-size: 15px;
  ul {
    padding-inline-start: 0;
  }
`

const FooterInner = styled.div`
  padding: 43px 40px 63px;
  width: 100%;

  @media (min-width: ${p => p.theme.global.breakpoints.small.value}px) {
    padding: 67px 70px 83px;
  }
`

const LinkList = styled.ul`
  margin-left: 0;
  margin-bottom: 0;
`

const Branding = styled(Heading)`
  font-size: 1.9rem;
`

const FooterHeading = styled(Heading)`
  text-transform: none;
  font-size: 1.2rem;
  margin-bottom: 17px;
`

const FooterSubHeading = styled(Heading)`
  text-transform: none;
  font-size: 17px;
  line-height: 1.5;
`

const FooterRow = styled(Box)`
  margin: 44px 0 0;
  @media (min-width: ${p => p.theme.global.breakpoints.small.value}px) {
    margin: 65px 0 65px;
  }
`

const FooterCategory = styled(Box)`
  flex-grow: 1;
  flex-shrink: 0;
  margin-bottom: ${p => (p.collapse ? 0 : "40px")};
  @media (min-width: ${p => p.theme.global.breakpoints.small.value}px) {
    flex-grow: 1;
    margin-bottom: 0;
  }
`

const FooterColumn = styled(Box)`
  flex-grow: 1;
`

const FooterSubmenu = styled(Box)`
  margin-bottom: 18px;
  padding-right: 1em;
  letter-spacing: 0.05em;
`

const SubmenuTitle = styled(Heading)`
  font-size: 1.06667rem;
  line-height: 1.5;
  text-transform: none;
`

const ListItem = styled.li`
  list-style-type: none;
`

const StyledSmartLink = styled(SmartLink)`
  font-weight: 400;
  display: block;
  text-decoration: none;
  line-height: 1.53333;
  @media (min-width: ${p => p.theme.global.breakpoints.small.value}px) {
    line-height: 1.58824;
  }
  &:hover {
    text-decoration: none;
  }
`

const FooterLink = ({ children, size, ...props }) => (
  <ListItem>
    <StyledSmartLink {...props} color="white" size={size || "medium"}>
      {children}
    </StyledSmartLink>
  </ListItem>
)

const NavigationCategoryMenus = ({ menus }) => (
  <>
    {menus.map(x => (
      <FooterSubmenu key={x.id}>
        <SubmenuTitle level={4} margin="none">
          {x.title}
        </SubmenuTitle>
        <LinkList>
          {x.links.map(z => (
            <FooterLink key={z.url} to={z.url}>
              {z.text}
            </FooterLink>
          ))}
        </LinkList>
      </FooterSubmenu>
    ))}
  </>
)

const NavigationCategory = ({ category }) => {
  const size = useContext(ResponsiveContext)
  const isMobile =
    ["small", "tablet", "xsmall", "xxsmall", "tiny"].indexOf(size) > -1
  return (
    <FooterCategory collapse={category.collapse} flex={{ shrink: 1 }}>
      {!category.collapse && (
        <FooterHeading level={3}>{category.title}</FooterHeading>
      )}
      <Box direction="row">
        {category.collapse ? (
          <Box
            direction="row"
            fill="horizontal"
            gap={isMobile ? "small" : "80px"}
          >
            {category.categories.map(x => (
              <FooterColumn key={x.id} basis="50%">
                <NavigationCategory category={x} />
              </FooterColumn>
            ))}
          </Box>
        ) : (
          <Box direction="row" flex={{ grow: 1 }} gap="small">
            {category.menus.map((x, i) => (
              <FooterColumn key={`menu-${i}`} basis="50%">
                <NavigationCategoryMenus menus={x} />
              </FooterColumn>
            ))}
          </Box>
        )}
      </Box>
    </FooterCategory>
  )
}

const SocialLink = ({ service, url }) => {
  let Icon = IconInstagram
  switch (service) {
    case "instagram":
      Icon = IconInstagram
      break
    case "pinterest":
      Icon = IconPinterest
      break
    case "facebook":
      Icon = IconFacebook
      break
    case "spotify":
      Icon = IconSpotify
      break
    case "twitter":
      Icon = IconTwitter
      break
    default:
      Icon = IconInstagram
  }
  return (
    <a target="_blank" style={{ display: "block", width: "20px" }} href={url}>
      <Icon />
    </a>
  )
}

const SocialLinks = ({ links }) => (
  <Box gap="small" direction="row">
    {links.map(x => (
      <SocialLink key={x.url} service={x.service} url={x.url} />
    ))}
  </Box>
)

const Footer = () => (
  <FooterContainer
    background="black"
    direction="row"
    flex="grow"
    style={{ width: "100%", letterSpacing: "0.05em" }}
  >
    <FooterInner>
      <Box direction="row">
        <Branding level={2} margin="none" style={{ fontWeight: 500 }}>
          Backdrop
        </Branding>
      </Box>
      <FooterRow
        direction="row-responsive"
        gap="80px"
        justify="start"
        align="start"
        alignItems="start"
        wrap={true}
      >
        {NAVIGATION.map(x => (
          <NavigationCategory key={x.id} category={x} />
        ))}
        <FooterCategory>
          <FooterHeading level={3}>Stay in the loop.</FooterHeading>
          <Box>
            <SocialLinks links={SOCIAL_LINKS} />
          </Box>
        </FooterCategory>
      </FooterRow>
      <FooterRow direction="row" justify="between" direction="row-responsive">
        <Box flex={{ grow: 1 }}>
          <Text size="small">
            Backdrop Inc &copy; Backdrop {new Date().getFullYear()} All Rights
            Reserved
          </Text>
        </Box>
        <Box gap="small" direction="row">
          <StyledSmartLink
            size="small"
            color="#fff"
            to="https://www.backdrophome.com/pages/privacy-policy"
          >
            Privacy Policy
          </StyledSmartLink>
          <StyledSmartLink
            size="small"
            color="#fff"
            to="https://www.backdrophome.com/pages/terms-of-service"
          >
            Terms of Use
          </StyledSmartLink>
          <StyledSmartLink
            size="small"
            color="#fff"
            to="https://www.backdrophome.com/pages/warranty"
          >
            Warranty
          </StyledSmartLink>
          <StyledSmartLink
            size="small"
            color="#fff"
            to="https://www.backdrophome.com/pages/return-policy"
          >
            Return Policy
          </StyledSmartLink>
        </Box>
      </FooterRow>
    </FooterInner>
  </FooterContainer>
)

export default Footer
