import React from "react"

const Logo = ({ fill }) => (
  <svg viewBox="0 0 123 162" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <polygon
        id="path-1"
        points="0.579501622 0.339180149 28.9568051 0.339180149 28.9568051 34.8839841 0.579501622 34.8839841"
      ></polygon>
    </defs>
    <g
      id="Backdrop-Final-Design"
      stroke="none"
      strokeWidth="1"
      fill={fill || "#fff"}
      fillRule="evenodd"
      className="fillColor"
    >
      <g
        id="02.2-Collection-Page-INTERSTITIAL-CONTENT"
        transform="translate(-1278.000000, -99.000000)"
      >
        <g id="Group-Copy" transform="translate(1278.748592, 99.500000)">
          <path
            d="M16.5790007,19.8052311 C14.9703316,18.9662065 12.870495,19.0138107 9.16198918,19.0138107 L5.85327098,19.0138107 L5.85327098,28.744909 L12.4668999,28.744909 C14.433474,28.744909 16.668477,28.8837547 18.1419798,27.0668599 C18.5455749,26.6027186 19.1262188,25.763694 19.1262188,24.1808533 C19.1262188,23.2486038 18.85779,21.0151719 16.5790007,19.8052311 M16.8474295,7.97954655 C15.3282367,6.02380604 12.5563761,6.21025595 10.4565394,6.21025595 L5.85327098,6.21025595 L5.85327098,14.4021508 L7.90932141,14.4021508 C9.47420424,14.4021508 11.0371833,14.4021508 12.6477562,14.3089258 C14.121259,14.2176844 15.5509755,14.0312345 16.622787,12.8212936 C16.9806921,12.4483938 17.607026,11.6569734 17.607026,10.2605826 C17.607026,9.88768283 17.5175497,8.86220834 16.8474295,7.97954655 M21.0033167,32.5631651 C18.2333598,34.1916265 15.417713,34.0527808 12.3774236,34.0527808 L0.00114225024,34.0527808 L0.00114225024,1.18007549 L11.3056121,1.18007549 C12.870495,1.18007549 14.479164,1.18007549 16.0421431,1.32090467 C17.0263821,1.41412962 19.6173864,1.69182097 21.6734368,3.97484058 C22.343557,4.7206402 23.6837972,6.4879473 23.6837972,9.23510816 C23.6837972,10.1673577 23.4610584,13.426264 20.8243641,15.1479504 C20.0647677,15.6596959 19.3508613,15.8481293 18.4560986,16.0345792 C18.85779,16.1278042 19.8401252,16.3122706 20.8681504,16.9192245 C22.4787232,17.8018863 25.1592038,19.991681 25.1592038,24.7402031 C25.1592038,28.0447301 23.8189635,30.8851159 21.0033167,32.5631651"
            id="Fill-1"
            fill=""
          ></path>
          <path
            d="M41.5602039,7.09450455 L37.1815779,22.2266996 L45.8950435,22.2266996 L41.5602039,7.09450455 Z M49.2913342,34.0523841 L47.4580226,27.6277964 L35.6166951,27.6277964 L33.7852872,34.0523841 L27.4400871,34.0523841 L38.1182231,1.18166229 L44.8213283,1.18166229 L55.5927481,34.0523841 L49.2913342,34.0523841 Z"
            id="Fill-3"
            fill=""
          ></path>
          <g id="Group-7" transform="translate(57.112512, 0.004053)">
            <mask id="mask-2" fill="white">
              <use href="#path-1"></use>
            </mask>
            <g id="Clip-6"></g>
            <path
              d="M26.5885396,29.298421 C25.113133,31.2541615 21.6730561,34.8839841 15.3259522,34.8839841 C14.2541407,34.8839841 11.7069227,34.7947262 8.98075214,33.4875933 C5.40741264,31.7202862 0.579501622,27.251439 0.579501622,17.5203407 C0.579501622,15.7490666 0.579501622,11.0481487 3.48462473,6.71616366 C5.00381755,4.43711108 8.62475081,0.33719664 15.5505948,0.33719664 C21.5835798,0.33719664 24.7571317,3.45924082 26.1868483,5.55382701 C27.2148735,7.1366677 27.8393036,8.76512912 28.3761612,10.488799 L22.5221287,12.0716397 C22.4764387,11.6531192 22.2993899,10.582024 21.7168423,9.46530803 C20.5117683,7.1366677 18.1415991,5.92672682 15.640071,5.97234754 C13.8524494,5.97234754 12.6016854,6.52971375 11.9753515,6.85897635 C9.65277603,8.06693373 6.97039172,11.2345986 6.97039172,17.3795115 C6.97039172,18.311761 7.01608173,22.2232421 8.84748961,25.1548693 C9.47382349,26.1327396 10.2334199,26.9697806 11.1262788,27.5767346 C13.0033767,28.9275047 14.8804746,29.0663503 15.7295473,29.0663503 C18.1872891,29.0663503 20.018697,28.0408759 21.5397935,26.0871189 C22.1642237,25.293715 22.6553913,24.3158447 22.7905575,24.0837741 C22.9695101,23.6652536 23.1027726,23.2943373 23.3712014,22.4553127 L28.9568051,24.877178 C28.286685,26.5512601 27.6603511,27.8544259 26.5885396,29.298421"
              id="Fill-5"
              fill=""
            ></path>
          </g>
          <polygon
            id="Fill-8"
            fill=""
            points="109.755018 34.0521858 101.351864 18.5946951 96.2136415 24.7415915 96.2136415 34.0521858 90.359609 34.0521858 90.359609 1.18146394 96.2136415 1.18146394 96.2136415 16.2660548 108.860255 1.18146394 115.786099 1.18146394 105.463965 13.5664982 116.50191 34.0521858"
          ></polygon>
          <path
            d="M114.073676,56.8411248 C114.521057,56.2817751 115.32444,55.1630756 115.771821,53.3957685 C116.173513,51.8129279 116.129726,50.9739033 116.129726,48.0878967 L116.129726,44.8270069 L95.2151244,44.8270069 L95.2151244,46.4574518 C95.2151244,49.7639624 95.2608144,51.7197029 95.4835532,53.2093186 C95.6187195,54.0463597 95.9747208,56.375 97.941295,58.0490822 C100.444727,60.1000311 105.046091,60.0068062 105.449687,60.0068062 C110.142431,60.0068062 112.643959,58.5171905 114.073676,56.8411248 M90.7470222,57.2120411 C89.8979495,54.3736388 89.8979495,52.0906192 89.8979495,49.1589919 L89.8979495,38.682094 L121.446901,38.682094 L121.446901,50.4621578 C121.446901,51.9517735 121.357425,53.7666849 121.088996,55.2106799 C120.776781,56.8411248 119.748756,60.3777225 116.352465,63.1268669 C114.521057,64.5688784 110.9896,66.5246189 105.403997,66.5246189 C99.1482727,66.5246189 92.8468589,64.0095287 90.7470222,57.2120411"
            id="Fill-10"
            fill=""
          ></path>
          <path
            d="M115.414107,87.1729543 C116.084227,86.1018591 116.352655,84.844314 116.352655,82.2379823 L116.352655,77.2554061 L106.521688,77.2554061 L106.521688,79.4452007 C106.521688,83.0294026 106.521688,85.264818 107.281285,86.7544338 C107.772452,87.6866833 108.935644,89.2219198 111.482862,89.2219198 C112.286245,89.2219198 114.163343,89.1286948 115.414107,87.1729543 Z M89.8981399,89.269524 L101.606205,82.797332 L101.606205,77.2554061 L89.8981399,77.2554061 L89.8981399,71.1580974 L121.447092,71.1580974 L121.447092,84.6122433 C121.447092,86.1018591 121.313829,90.5250856 117.603419,93.4586964 C115.191368,95.3688162 112.465197,95.4620411 111.437172,95.4620411 C110.946004,95.4620411 109.381122,95.4144369 107.907619,94.8550872 C106.611165,94.3433417 103.884994,92.760501 102.767493,88.9898491 L89.8981399,96.2990822 L89.8981399,89.269524 Z"
            id="Fill-12"
            fill=""
          ></path>
          <path
            d="M112.688697,123.071702 C115.369178,121.350016 116.844585,118.7417 116.844585,115.43519 C116.844585,113.386224 116.218251,111.896609 115.906036,111.246017 C114.699058,108.917377 111.750149,106.031371 105.85233,106.031371 C104.643448,106.031371 98.8351058,106.078975 95.9299827,110.686668 C94.6792187,112.690012 94.5897424,114.68939 94.5897424,115.528415 C94.5897424,116.180989 94.6354324,117.670605 95.3493388,119.30105 C96.423054,121.720932 99.1035346,124.886613 105.047043,125.025459 C107.639951,125.073063 110.409908,124.561318 112.688697,123.071702 M93.6074072,126.657887 C92.8478108,125.912088 91.0144991,124.003951 89.9883777,121.068357 C89.6304726,120.04685 89.093615,118.13673 89.093615,115.343948 C89.093615,110.686668 90.5690216,107.148087 93.9653123,104.07563 C97.315913,101.003174 101.249061,99.7000078 105.627687,99.7000078 C106.655712,99.7000078 109.069668,99.7456285 111.750149,100.675895 C118.049659,102.818085 122.251236,108.453236 122.251236,115.482794 C122.251236,121.814157 118.944421,125.586792 117.246276,127.122029 C115.369178,128.843715 111.704459,131.26558 105.671473,131.217976 C103.706803,131.217976 98.1211994,130.985905 93.6074072,126.657887"
            id="Fill-14"
            fill=""
          ></path>
          <path
            d="M114.073676,153.591568 C116.398155,152.195177 116.398155,149.448017 116.398155,147.073755 L116.398155,142.370854 L105.449687,142.370854 L105.449687,146.794081 C105.449687,147.212601 105.493473,147.678726 105.493473,148.095263 C105.626735,151.262928 106.567188,152.659319 107.817952,153.452723 C108.35481,153.778018 109.516097,154.335384 111.124767,154.335384 C112.107102,154.335384 113.180817,154.103314 114.073676,153.591568 M102.411301,156.291125 C100.667466,153.452723 100.623679,150.378283 100.623679,147.16698 L100.623679,142.370854 L89.8979495,142.370854 L89.8979495,136.223958 L121.446901,136.223958 L121.446901,147.305826 C121.446901,148.095263 121.490687,148.888667 121.446901,149.680087 C121.357425,151.124082 121.088996,155.267634 117.782182,158.201245 C115.145487,160.575506 112.196578,160.666747 110.945814,160.666747 C106.03033,160.666747 103.483112,158.012811 102.411301,156.291125"
            id="Fill-16"
            fill=""
          ></path>
        </g>
      </g>
    </g>
  </svg>
)

export default Logo
