import React from "react"

const IconInstagram = ({ className }) => (
  <svg
    className={className}
    viewBox="0 0 18 17"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Instagram Icon</title>
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="footer" transform="translate(-1053.000000, -250.000000)">
        <g id="Group-30" transform="translate(0.000000, 4.000000)">
          <g id="Group-9-Copy" transform="translate(894.279444, 246.000000)">
            <g id="Group-8" transform="translate(158.883687, 0.000000)">
              <rect
                id="Rectangle-13"
                stroke="#FFFFFF"
                strokeWidth="2"
                x="1"
                y="1"
                width="14.987564"
                height="15"
                rx="4"
              ></rect>
              <ellipse
                id="Oval-4"
                stroke="#FFFFFF"
                strokeWidth="2"
                strokeLinecap="square"
                cx="8.493782"
                cy="8.5"
                rx="3.49743965"
                ry="3.5"
              ></ellipse>
              <ellipse
                id="Oval-5"
                fill="#FFFFFF"
                cx="12.9904901"
                cy="4"
                rx="1"
                ry="1"
              ></ellipse>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default IconInstagram
