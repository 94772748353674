import React from "react"

const IconPinterest = ({ className }) => (
  <svg
    className={className}
    viewBox="0 0 15 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Pinterest Icon</title>
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="footer"
        transform="translate(-894.000000, -250.000000)"
        fill="#FFF"
        fillRule="nonzero"
      >
        <g id="Group-30" transform="translate(0.000000, 4.000000)">
          <g id="Group-9-Copy" transform="translate(894.279444, 246.000000)">
            <g id="Group-18">
              <g id="icon-share-pinterest">
                <g id="Group">
                  <path
                    d="M5.81074616,11.754 C5.3880556,13.604 4.97435845,15.387 4.07301829,16.794 C3.79722019,17.224 3.51942356,17.767 3.04676957,18 C2.34928018,14.408 3.80721287,11.385 4.38279151,8.366 C3.64832919,6.866 4.10499488,4.327 5.67784345,4.039 C7.95617557,3.622 7.35561522,6.372 6.96989759,7.651 C6.7570534,8.352 6.37533285,9.024 6.43229115,9.793 C6.55919824,11.415 8.59770593,11.52 9.64294075,10.684 C11.1398449,9.49 11.5735274,7.183 11.426635,5.288 C11.2047974,2.402 7.939188,0.967 5.05429993,2.12 C3.56638917,2.715 2.21837601,4.082 2.02152012,6.09 C1.920594,7.147 2.14143233,7.992 2.55912655,8.588 C2.6210812,8.68 2.83692319,8.838 2.86889978,9.081 C2.93385223,9.569 2.64406437,10.096 2.37925823,10.461 C0.901340161,10.036 0.138898317,8.709 0.016987564,7.029 C-0.262807608,3.205 2.87489539,0.296 6.56719239,0.025 C10.5083072,-0.265 13.6320205,2.012 13.9218083,5.333 C14.1376503,7.799 13.2622911,10.283 11.873308,11.62 C10.8280732,12.624 8.60669934,13.608 6.79102853,12.647 C6.3893226,12.434 6.21045355,12.173 5.81074616,11.754"
                    id="Shape"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default IconPinterest
